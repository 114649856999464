<template>
  <div class="map-picker">
    <van-popup v-model="value" position="bottom" :style="{ height: '100%' }">
      <div class="map-container">
        <div class="map-btns">
          <van-button plain size="small" @click="cancelSelection"
            >取消</van-button
          >
          <van-button type="primary" size="small" @click="confirmLocation"
            >发送</van-button
          >
        </div>
        <div id="mapContainer" style="width: 100%; height: 100%;"></div>
        <div class="map-search">
          <van-search
            v-model="keywords"
            show-action
            label="地址"
            placeholder="请输入搜索关键词"
            @search="onSearch"
          >
            <template #action>
              <div @click="onSearch">搜索</div>
            </template>
          </van-search>
          <div class="searchList">
            <div
              class="item"
              v-for="item in searchResult"
              :key="item.id"
              @click="selectLocation(item)"
            >
              {{ item.address }}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Api from "@/api/user";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      address: "",
      map: null,
      keywords: "",
      marker: null,
      selectedLocation: null,
      searchResult: [],
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.showMap();
        } else {
          this.cancelSelection();
        }
      },
      immediate: true,
    },
  },
  methods: {
    showMap() {
      this.$nextTick(() => {
        this.initMap();
      });
    },
    initMap() {
      if (this.map) return;

      // 确保天地图 JS API 已加载
      if (!window.T) {
        console.error("天地图 JS API 未加载");
        return;
      }
      
      var pixelRatio = window.devicePixelRatio || 1;
      this.map = new window.T.Map("mapContainer", {
        projection: 'EPSG:4326',
        zoom: 10,
        minZoom: 1,
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1, // 调整缩放偏移
        //1.设置缩放级别为整数
        constrainResolution: true,
        //2.关闭无级缩放地图
        smoothResolutionConstraint: false,
        // 使用高分辨率瓦片
        tileUrl: 'http://t{s}.tianditu.gov.cn/img_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=862f1ed5b50768c2fe67f164211dae9d',
        subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
        dpr: pixelRatio > 1 ? 2 : 1, // 设置 DPR
      });

      // 尝试获取用户位置信息
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lng = position.coords.longitude;
            const lat = position.coords.latitude;
            // 使用用户位置进行中心和缩放
            this.map.centerAndZoom(new window.T.LngLat(lng, lat), 11);
            // // 可以在用户位置上设置初始标记
            this.updateMarker(new window.T.LngLat(lng, lat));
            this.selectedLocation = new window.T.LngLat(lng, lat);
          },
          (error) => {
            console.error("获取用户位置失败:", error);
            // 默认位置
            this.map.centerAndZoom(new window.T.LngLat(116.404, 39.915), 11);
          }
        );
      } else {
        console.error("浏览器不支持地理位置服务");
        // 默认位置
        this.map.centerAndZoom(new window.T.LngLat(116.404, 39.915), 11);
      }
      this.map.addEventListener("click", (e) => {
        const lnglat = e.lnglat;
        this.updateMarker(lnglat);
        this.selectedLocation = lnglat;
      });
    },
    updateMarker(lnglat) {
      if (this.marker) {
        this.map.removeOverLay(this.marker);
      }
      this.marker = new window.T.Marker(lnglat);
      this.map.addOverLay(this.marker);
      // 将视图移动到中心位置
      this.map.panTo(lnglat);
    },
    async confirmLocation() {
      if (this.selectedLocation) {
        let lng = this.selectedLocation.lng;
        let lat = this.selectedLocation.lat;
        // 这里调用天地图获取地址
        let address = await this.getGeocoder(lng, lat);
        this.$emit("onConfirm", {
          address: address,
          lng: lng,
          lat: lat,
        });
      }
    },
    async getGeocoder(lng, lat) {
      let T = window.T;
      let geocoder = new T.Geocoder();
      let lngLat = new T.LngLat(lng, lat);
      return new Promise((resolve) => {
        geocoder.getLocation(lngLat, (result) => {
          resolve(result.getAddress());
        });
      });
    },
    cancelSelection() {
      this.$emit("input", false);
    },
    async onSearch() {
      if (this.keywords) {
        let res = await Api.skyMapSearch({ keyWord: this.keywords });
        let data = JSON.parse(res.data||"{}");
        this.searchResult = data.pois;
      }
    },
    selectLocation(item) {
      let lng = item.lonlat.split(",")[0];
      let lat = item.lonlat.split(",")[1];
      let T = window.T;
      let lngLat = new T.LngLat(lng, lat);
      this.updateMarker(lngLat);
      this.selectedLocation = lngLat;
    },
  },
};
</script>

<style lang="less" scoped>
.map-container {
  position: relative;
  height: 100%;
}
.map-btns {
  padding: 0 20px;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  z-index: 99999;
}
.map-search {
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 99999;
  .searchList {
    padding: 0 10px;
    max-height: 30vh;
    overflow-y: auto;
    .item {
      padding: 15px 0;
      border-bottom: 1px solid #eee;
      &:last-child {
        border: none;
      }
    }
  }
}
</style>
